import { relative, basename } from '@sentry/utils';

/** Rewrite event frames paths */
class RewriteFrames {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = 'RewriteFrames';
  }

  /**
   * @inheritDoc
   */

  /**
   * @inheritDoc
   */

  /**
   * @inheritDoc
   */

  /**
   * @inheritDoc
   */
  constructor(options = {}) {
    RewriteFrames.prototype.__init.call(this);
    this.name = RewriteFrames.id;
    if (options.root) {
      this._root = options.root;
    }
    this._prefix = options.prefix || 'app:///';
    if (options.iteratee) {
      this._iteratee = options.iteratee;
    }
  }

  /**
   * @inheritDoc
   */
  setupOnce(_addGlobaleventProcessor, _getCurrentHub) {
    // noop
  }

  /** @inheritDoc */
  processEvent(event) {
    return this.process(event);
  }

  /**
   * TODO (v8): Make this private/internal
   */
  process(originalEvent) {
    let processedEvent = originalEvent;
    if (originalEvent.exception && Array.isArray(originalEvent.exception.values)) {
      processedEvent = this._processExceptionsEvent(processedEvent);
    }
    return processedEvent;
  }

  /**
   * @inheritDoc
   */
  __init() {
    this._iteratee = frame => {
      if (!frame.filename) {
        return frame;
      }
      // Determine if this is a Windows frame by checking for a Windows-style prefix such as `C:\`
      const isWindowsFrame = /^[a-zA-Z]:\\/.test(frame.filename) ||
      // or the presence of a backslash without a forward slash (which are not allowed on Windows)
      frame.filename.includes('\\') && !frame.filename.includes('/');
      // Check if the frame filename begins with `/`
      const startsWithSlash = /^\//.test(frame.filename);
      if (isWindowsFrame || startsWithSlash) {
        const filename = isWindowsFrame ? frame.filename.replace(/^[a-zA-Z]:/, '') // remove Windows-style prefix
        .replace(/\\/g, '/') // replace all `\\` instances with `/`
        : frame.filename;
        const base = this._root ? relative(this._root, filename) : basename(filename);
        frame.filename = `${this._prefix}${base}`;
      }
      return frame;
    };
  }

  /** JSDoc */
  _processExceptionsEvent(event) {
    try {
      return {
        ...event,
        exception: {
          ...event.exception,
          // The check for this is performed inside `process` call itself, safe to skip here
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          values: event.exception.values.map(value => ({
            ...value,
            ...(value.stacktrace && {
              stacktrace: this._processStacktrace(value.stacktrace)
            })
          }))
        }
      };
    } catch (_oO) {
      return event;
    }
  }

  /** JSDoc */
  _processStacktrace(stacktrace) {
    return {
      ...stacktrace,
      frames: stacktrace && stacktrace.frames && stacktrace.frames.map(f => this._iteratee(f))
    };
  }
}
RewriteFrames.__initStatic();
export { RewriteFrames };
