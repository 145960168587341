import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy,
  withHashLocation,
  withPreloading,
} from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as SentryAngular from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import packageJson from '../package.json';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { authInterceptor } from './app/interceptors/auth.interceptor';
import { environment } from './environments/environment';
import { httpInterceptor } from './app/interceptors/http.interceptor';

Sentry.init(
  {
    dsn: 'https://aead83fa857900a8769ae08e28ddd254@o521953.ingest.sentry.io/4506716192047104',
    release: packageJson.version,
    dist: packageJson.versionNumber,
    environment: environment.env,
    tracesSampleRate: 1.0,
    integrations: [new SentryAngular.BrowserProfilingIntegration()],
    tracePropagationTargets: [
      'localhost',
      'https://s2atesting.amersports.recette.sully-group.fr/api',
      'https://qs2atesting.amersports.com/api',
      'https://s2atesting.amersports.com/api',
    ],
    enabled: false,
  },
  SentryAngular.init,
);

if (environment.production) {
  enableProdMode();
}

function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
      IonicStorageModule.forRoot({
        name: 's2a-testing',
        description: 'S2A Testing local db for offline capability',
        driverOrder: [Drivers.IndexedDB],
      }),
      PinchZoomModule,
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptors([httpInterceptor, authInterceptor]), withFetch()),
    provideIonicAngular({
      swipeBackEnabled: false,
    }),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules), withHashLocation()),
  ],
}).catch((err) => {
  console.error(err);
});
